<template>
  <base-view
    title="Videos"
    description="Videotutoriales de apoyo a procesos."
    header-type="simplified"
    background="bg-gradient-black-to-blue"
  >
    <b-overlay :show="loading">
      <template #overlay>
        <div class="text-center">
          <loading message="Obteniendo archivo, por favor espere..."></loading>
        </div>
      </template>

      <h4 class="mb-0 mt-5">Videotutoriales.</h4>
      <hr class="mt-2 mb-4" />

      <b-row class="row">
        <b-col cols="12" lg="4" class="mb-4" v-for="item in layouts" :key="item.id">
          <!-- Layout para nóminas -->
          <div class="card lift lift-sm h-100 my-cursor">
            <div class="card-body">
              <h5 class="card-title mb-2" :class="item.textColor">
                <i class="mr-2" :data-feather="item.icon"></i>
                {{ item.title }}
              </h5>
              <p class="card-text mb-1">
                <iframe :src="item.url" width="100%" height="100%"
                        allow="autoplay"></iframe>
              </p>
            </div>
            <div class="card-footer">
              <div class="small text-muted" @click="onDownload(item.filename, item.ext)">{{ item.desc }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </base-view>
</template>

<script>

export default {
  name: 'VideosAyudaView',

  data () {
    return {
      layouts: [
        {
          title: 'Exportación de pólizas',
          desc: 'Procedimiento de exportación de pólizas en SAACG.NET.',
          url: 'https://drive.google.com/file/d/1Ci_xMoTDGjQstAfm-Hc5s3e3pV48Kjj-/preview',
          icon: 'download'
        }
      ],

      loading: false
    }
  }
}
</script>
